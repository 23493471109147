<template>
  <div key="calendarTitle">

    <edit-task
        v-model="showEdit"
        :opts="selected"
        @onChange="onTaskEditChanged"
    ></edit-task>

    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ calendarTitle }}</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRef"
              :id="tableName"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="tasks"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
              :scroll-width="1500"
            />
            <div v-show="showEmpty" class="empty-data">Datos vacíos.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltasks"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import moment from "moment";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import {objectToQueryParamsFilterModel} from "../../../utils";
import editTask from "../common/editTask";
import {getDateFromRecurrence, isDateWithinRecurrence } from "./common";
import {getTaskDelayStatus} from "../common/common";

export default {
  name: "CalendarTasks",
  props: ["calendarTitle", "isToday", "tableName", "filters"],
  components: {
    editTask,
  },
  data: function() {
    return {

      filterable_col_list: [
        "name",
        "priority",
        "hour",
        "brandId",
        "projectId",
        "type",
        "responsibleId",
        "status",
        "task_endDate"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          let className = "";
          if (this.filterable_col_list.includes(column.field)) {
            className += "filterable-column " + column.field;
          }
          if (rowIndex === 0) {
            className += " table-header-cell-class";
          }
          return className;
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          let className = "";
          let today = moment(new Date())
            .local()
            .format("YYYY-MM-DD");
          if (this.getTaskDelayStatus(row) === "Completed")
            className += "table-body-cell-completed";
          if (this.getTaskDelayStatus(row) === "Pending")
            className += "table-body-cell-pending";
          if (this.getTaskDelayStatus(row) === "Delayed")
            className += "table-body-cell-delayed";
          if (this.getTaskDelayStatus(row) === "Overdue")
            className += "table-body-cell-overdue";
          return className;
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        status: {
          pending: "Pending",
          delayed: "Delayed"
        },
        name: {},
        brandId: {},
        projectId: {},
        responsibleId: {},
        type: {},
        priority: {},
        description: {},
        task_endDate: {}
      },
      showEmpty: false,
      select_task_startDate_menu: false,
      select_task_endDate_menu: false,

      select_recurrence_startDate_menu: false,
      select_recurrence_endDate_menu: false,

      totaltasks: 0,
      tasks: [],
      filtered_brands: null,
      filtered_projects: null,

      valid: true,
      requiredRules: [v => !!v || "Required"],
      selected: null,
      editedIndex: -1,
      deleteItemId: -1,
      showEdit: false,
      dialogDelete: false,
      WORKINGDAYS_ITEMS: [
        { text: "Domingo", value: "Sun" },
        { text: "Lunes", value: "Mon" },
        { text: "Martes", value: "Tue" },
        { text: "Miercoles", value: "Wed" },
        { text: "Jueves", value: "Thu" },
        { text: "Viernes", value: "Fri" },
        { text: "Sabado", value: "Sat" }
      ],
      FREQ_ITEMS: [
        { text: "días", value: "days" },
        { text: "semanas", value: "weeks" },
        { text: "meses", value: "months" },
        { text: "años", value: "years" }
      ]
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    async filters(val, old) {
      await this.getDataFromApi()
    }
  },
  computed: {
    formTitle() {
      return "Mis Tareas";
    },
    columns() {
      return [
        {
          key: "priority",
          title: "Prioridad",
          field: "priority",
          align: "left",
          width: 80,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["priority"]["high"]}
                    label="Alta"
                    value="Alta"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["priority"]["medium"]}
                    label="Media"
                    value="Media"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["priority"]["low"]}
                    label="Baja"
                    value="Baja"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "priority")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "task_endDate",
          title: "Fecha límite",
          field: "task_endDate",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.task_endDate} {row.hour}
              </span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["task_endDate"]["from"]}
                    value={this.filterCriteria["task_endDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["task_endDate"]["to"]}
                    value={this.filterCriteria["task_endDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "task_endDate")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "name",
          title: "Tarea",
          field: "name",
          align: "left",
          width: 100,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["name"]}
                    apiUrl={`tasks/getfilterlist`}
                    columnName={`name`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["name"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        // {
        //   key: "hour",
        //   title: "Hora",
        //   field: "hour",
        //   align: "left",
        //   width: 70,
        //   sortBy: ""
        // },
        {
          key: "brandId",
          title: "Marca",
          field: "brandId",
          align: "left",
          width: 110,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.brand?.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["brandId"]}
                    apiUrl={`tasks/getfilterlist`}
                    columnName={`brandId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["brandId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "brandId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "projectId",
          title: "Proyecto",
          field: "projectId",
          align: "left",
          width: 110,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row?.project?.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["projectId"]}
                    apiUrl={`tasks/getfilterlist`}
                    columnName={`projectId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["projectId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "projectId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "type",
          title: "Tipo Tarea",
          field: "type",
          align: "left",
          width: 120,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["type"]["puntual"]}
                    label="Puntual"
                    value="Puntual"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["type"]["recurrente"]}
                    label="Recurrente"
                    value="Recurrente"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "type")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "status",
          title: "Estado",
          field: "status",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
                    this.getTaskDelayStatus(row) === "Completed"
                        ? <div class="table-cell-completed"><span>A tiempo - Finalizada</span></div>
                        : this.getTaskDelayStatus(row) === "Pending"
                            ? <div class="table-cell-pending"><span>A tiempo - Pendiente</span></div>
                            : this.getTaskDelayStatus(row) === "Delayed"
                                ? <div class="table-cell-delayed"><span>Con retraso - Pendiente</span></div>
                                : <div class="table-cell-completed"><span>Con retraso - Finalizada</span></div>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["completed"]}
                    label="A tiempo - Finalizada"
                    value="Completed"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["pending"]}
                    label="A tiempo - Pendiente"
                    value="Pending"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["delayed"]}
                    label="Con retraso - Pendiente"
                    value="Delayed"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["overdue"]}
                    label="Con retraso - Finalizada"
                    value="Overdue"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "status")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "responsibleId",
          title: "Responsable",
          field: "responsibleId",
          align: "left",
          width: 110,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row.tasksResponsible &&
                  row.tasksResponsible?.name +
                    " " +
                    row.tasksResponsible?.surname}
              </span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["responsibleId"]}
                    apiUrl={`tasks/getfilterlist`}
                    columnName={`responsibleId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["responsibleId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "responsibleId")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "actions",
          title: "Ir",
          field: "actions",
          align: "center",
          fixed: "right",
          width: 50,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-plus
                </v-icon>
              </div>
            );
          }
        }
      ];
    }
  },
  methods: {
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector(`#${this.tableName}`),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    close() {
      this.loadingInstance.close();
    },

    onItemEdit(item) {
      this.selected = item
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },

    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      let local_date = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      if (this.isToday) {
        query_params.push("daily=" + local_date);
      } else {
        let sevenDaysLater = new Date();
        sevenDaysLater.setDate(new Date().getDate() + 7);
        sevenDaysLater = moment(sevenDaysLater)
          .local()
          .format("YYYY-MM-DD");
        query_params.push("weekly_st=" + local_date);
        query_params.push("weekly_en=" + sevenDaysLater);
      }

      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      const filters = _.merge(this.filters, this.filterCriteria)

      const filterModel = objectToQueryParamsFilterModel(filters);

      let filter_columns = document.querySelectorAll(
        `#${this.tableName} .filterable-column`
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    formatDateToISO(date) {
      return date.toISOString().split("T")[0];
    },

    isSameOrBetween(tempDate) {
      let date = tempDate;
      // let local_date = this.formatDateToISO(new Date());
      let local_date = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      if (this.isToday) {
        return date <= local_date;
      } else {
        let sevenDaysLater = new Date();
        sevenDaysLater.setDate(sevenDaysLater.getDate() + 7);
        sevenDaysLater = this.formatDateToISO(sevenDaysLater);
        return local_date < date && date < sevenDaysLater;
      }
    },

    getTextFromFreq(freq) {
      const result = this.FREQ_ITEMS.filter(el => el.value === freq);
      return result[0].text;
    },

    getTextFromWorkingDays(workingDays) {
      let data_list = [];
      this.WORKINGDAYS_ITEMS.forEach(el => {
        if (workingDays.includes(el.value)) data_list.push(el.text);
      });
      return data_list;
    },

    getRecurrenceDescription(recurrence) {
      if (!recurrence) return "";

      let description = "Cada ";
      const frequencyText = this.getTextFromFreq(recurrence.freq);

      if (recurrence.interval === "1") {
        const sliceIndex = recurrence.freq === "months" ? -2 : -1;
        description += frequencyText.slice(0, sliceIndex);
      } else {
        description += `${recurrence.interval} ${frequencyText}`;
      }

      if (recurrence.freq === "days" && recurrence.isWeekend === "1") {
        description += " durante los días laborables";
      } else if (
        recurrence.freq === "weeks" &&
        recurrence.workingDays?.length
      ) {
        const workingDaysText = this.getTextFromWorkingDays(
          recurrence.workingDays
        );
        description += ` en ${workingDaysText.join(", ")}`;
      }

      return description;
    },

    getDateFromRecurrence,

    getTaskDelayStatus,

    async setCalendarItems(data) {
      let data_list = [];
      for (let el of data) {
        if (el.type === "Recurrente") {
          el = { ...el, recurrence: JSON.parse(el.recurrence) };
          let tempStartDate = el.recurrence_startDate;
          let tempEndDate = el.recurrence_endDate;
          let limit = 3000;
          let taskTrackingCur = 0;
          for (let i = 0; i < limit; i++) {
            if (tempEndDate < tempStartDate) break;
            let temp = el;
            if (
              taskTrackingCur < el.taskTrackingsTaskId.length &&
              el.taskTrackingsTaskId[taskTrackingCur].date === tempStartDate
            ) {
              const tracking = el.taskTrackingsTaskId[taskTrackingCur];
              tracking.recurrence = JSON.parse(tracking.recurrence)
              tracking.tasksResponsible = tracking.taskTrackingsResponsible

              temp = {
                ...temp,
                ...tracking,
              };
              taskTrackingCur++;
            }
            temp = { ...temp, task_endDate: tempStartDate };

            if (
              this.isSameOrBetween(tempStartDate) &&
              !temp.isRemoved &&
              this.isDateWithinRecurrence(tempStartDate, el.recurrence)
            ) {
              if (
                Object.values(this.filterCriteria["status"]).includes(
                  this.getTaskDelayStatus(temp)
                )
              )
                data_list.push(temp);
            }
            tempStartDate = this.getDateFromRecurrence(
              tempStartDate,
              el.recurrence
            );
          }
        } else {
          if (
            this.isSameOrBetween(el.task_endDate) &&
            Object.values(this.filterCriteria["status"]).includes(
              this.getTaskDelayStatus(el)
            )
          )
            data_list.push(el);
        }
      }
      this.tasks = data_list;
      this.sortTasks();
      this.totaltasks = data_list.length;
    },

    sortTasks() {
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            let isIncreasing = value === "desc" ? 1 : -1;
            this.tasks.sort((a, b) => {
              if (key === "status") {
                if (
                  this.getTaskDelayStatus(a) <
                  this.getTaskDelayStatus(b)
                )
                  return isIncreasing;
                return -isIncreasing;
              }
              if (a[key] < b[key]) {
                return isIncreasing;
              }
              return -isIncreasing;
            });
          }
        }
      }
    },

    isDateWithinRecurrence,

    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "tasks";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }

      const resp = await ApiService.get(fetch_url);

      await this.setCalendarItems(resp.data);
      this.filtered_brands = resp.brand_list;
      this.filtered_projects = resp.project_list;
      if (this.tasks.length > 0) {
        this.showEmpty = false;
      } else {
        this.showEmpty = true;
      }
      this.close();
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    async onTaskEditChanged() {
      await this.getDataFromApi()
      this.$emit('onChange')
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },

    async init() {
      await this.getDataFromApi()
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style>

.table-cell-completed, .table-cell-pending, .table-cell-overdue, .table-cell-delayed {
  padding-top: 3px;
  text-align: center;
  font-weight: bold;
}

.table-cell-completed {
  background-color: rgb(51, 182, 121) !important;
}
.table-cell-pending {
  background-color: #f8e580 !important;
}
.table-cell-overdue {
  background-color: orange !important;
}
.table-cell-delayed {
  background-color: indianred;
  color: white !important;
}
.table-cell-class {
  background-color: #004375ed !important;
}

.table-body-cell-completed {
  /*background-color: rgb(51, 182, 121) !important;*/
}
.table-body-cell-pending {
  /*background-color: #fff !important;*/
}
.table-body-cell-overdue {
  /*background-color: orange !important;*/
}
.table-body-cell-delayed {
  /*background-color: rgb(213, 0, 0) !important;*/
}
.table-header-cell-class {
  /*background-color: #004375ed !important;*/
}
</style>
