<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-4">
      <v-card-title>Vision Calendario</v-card-title>


      <edit-task
          v-model="showEdit"
          :opts="editOpts"
          @onChange="onTaskChanged"
      ></edit-task>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-form ref="form_search" v-model="valid" lazy-validation>
              <v-row style="align-items: center">
                <v-col cols="12" sm="6" md="3" lg="3">
                  <v-autocomplete
                    v-model="filterMainCredential.brandId"
                    v-bind:label="$t('brands.brand')"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_brands"
                    :loading="isSearching['brands']"
                    :items="filtered_brands"
                    hide-no-data
                    multiple
                    filled
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <v-autocomplete
                    v-model="filterMainCredential.projectId"
                    label="Proyecto"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_projects"
                    :loading="isSearching['projects']"
                    @change="onChangeFilterMainCredentials('projects')"
                    :items="filtered_projects"
                    hide-no-data
                    multiple
                    filled
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <v-autocomplete
                    v-model="filterMainCredential.responsibleId"
                    label="Responsable"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="title"
                    item-value="id"
                    :search-input.sync="search_responsible"
                    :loading="isSearching['responsible']"
                    :items="filtered_responsible"
                    hide-no-data
                    filled
                    multiple
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <v-autocomplete
                    label="Tipo de tarea"
                    v-model="filterMainCredential.type"
                    :items="TASK_TYPES"
                    hide-no-data
                    filled
                    clearable
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <v-autocomplete
                    label="Estado"
                    v-model="filterMainCredential.status"
                    :items="FILTERING_STATUS_TYPES"
                    item-text="text"
                    item-value="value"
                    hide-no-data
                    multiple
                    filled
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3">
                  <v-autocomplete
                    v-model="filterMainCredential.company"
                    label="Compañía"
                    v-bind:placeholder="$t('start_typing_to_search')"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="search_company"
                    @change="onChangeFilterMainCredentials('company')"
                    :loading="isSearching['company']"
                    :items="filtered_company"
                    hide-no-data
                    filled
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="2" lg="2">
                  <v-autocomplete
                      v-model="filterMainCredential.priority"
                      label="Prioridad"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="text"
                      item-value="value"
                      multiple
                      :items="PRIORITY_TYPES"
                      hide-no-data
                      filled
                      clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="1" lg="1">
                  <v-checkbox
                    label="Solo usuarios activos"
                    v-model="isActive"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="3" align="right">
                  <v-btn
                    color="blue darken-1"
                    class="mr-8"
                    @click="onClickFilter"
                  >
                    {{ $t("go") }}
                  </v-btn>
                  <v-btn color="primary" @click="onItemEdit">Nueva Tarea</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <div class="calendar" id="calendario">
        <calendar-view
          :show-date="showDate"
          class="theme-default holiday-us-traditional holiday-us-official locale-en"
          :displayPeriodUom="displayPeriodUom"
          :displayPeriodCount="displayPeriodCount"
          :periodChangedCallback="periodChangedCallback"
          :startingDayOfWeek="startingDayOfWeek"
          :locale="`es`"
          :monthNameFormat="`long`"
          :currentPeriodLabel="`Hoy`"
          :events="calendarItems"
          :enableDragDrop="true"
          :showEventTimes="true"
          :doEmitItemMouseEvents="true"
          :eventTop="eventTop"
          :timeFormatOptions="timeFormatOptions"
          @drop-on-date="dropOnDate"
          @click-date="onClickDate"
          @click-event="onClickEvent"
          @item-mouseenter="item_mouseenter"
        >
          <calendar-view-header
            slot="header"
            slot-scope="t"
            :header-props="t.headerProps"
            @input="setShowDate"
          />
        </calendar-view>
      </div>
    </v-card>
  </div>
</template>
<script>
import ApiService from "@/services/apiService";
import _ from "lodash";
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import moment from "moment";
import { mapGetters } from "vuex";

import {
  getStyleFromStatus,
  isTaskOnTime,
  TASK_TYPES,
  FREQ_ITEMS,
  WORKINGDAYS_ITEMS,
  PRIORITY_TYPES
} from "../common/common";

import editTask from "../common/editTask";
import {objectToQueryParamsFilterModel} from "../../../utils";
import { getDateFromRecurrence, isDateWithinRecurrence } from "./common";


// The next two lines are processed by webpack. If you're using the component without webpack compilation,
// you should just create <link> elements for these. Both are optional, you can create your own theme if you prefer.
require("vue-simple-calendar/static/css/default.css");
require("vue-simple-calendar/static/css/holidays-us.css");

export default {
  name: "Calendar",
  components: {
    CalendarView,
    CalendarViewHeader,
    editTask
  },
  data: function() {
    return {
      /**************** Calendar ************************/
      isInitializing: true,
      taskOpts: null,
      showDate: new Date(),
      displayPeriodUom: "month", // By default - "month" / "year", "week"
      displayPeriodUomItems: ["month", "year", "week"],
      displayPeriodCount: 1,
      enableDateSelection: true,
      startingDayOfWeek: 1, // Monday, Tue, Wed
      calendarItems: [],
      eventTop: "2.1em",
      timeFormatOptions: {
        // weekday: 'long', // Display full weekday names (e.g., 'Monday', 'Tuesday', etc.)
        // year: 'numeric', // Display the year in numeric format
        // month: 'long', // Display full month names (e.g., 'January', 'February', etc.)
        // day: 'numeric', // Display the day in numeric format
        hour: "numeric", // Display the hour
        minute: "numeric", // Display the minute
        hour12: true // Use a 12-hour clock format
      },

      MULTIPLIER: 1000003,
      SUB_MULTIPLIER: 10007,

      /********** form-validation options **********/
      filter_main_credentials_fetch_url:
        "tasks/columna/getfiltermaincredentials",
      FILTERING_STATUS_TYPES: [
        { text: "A tiempo / Finalizada", value: "Completed" },
        { text: "A tiempo / Pendiente", value: "Pending" },
        { text: "Con retraso / Finalizada", value: "Overdue" },
        { text: "Con retraso / Pendiente", value: "Delayed" }
      ],

      TASK_TYPES,

      FREQ_ITEMS,

      WORKINGDAYS_ITEMS,

      PRIORITY_TYPES,

      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      valid: true,
      filtered_brands: [],
      search_brands: null,
      filtered_projects: [],
      search_projects: null,
      filtered_responsible: [],
      search_responsible: null,
      filtered_company: [],
      search_company: null,
      isSearching: {},
      filterMainCredential: {
        priority: null,
        brandId: [],
        status: [],
        type: [],
        projectId: [],
        date: null,
        isActiveUser: false,
        responsibleId: [],
        company: null
      },
      isActive: null,
      loadingInstance: null,

      /************************** Edit Dialog *************************/
      editOpts: null,

      showEdit: false,
      tasksData: null,




    };
  },
  computed: {
    ...mapGetters("auth", ["user", "isBrand"]),



  },
  watch: {
    async search_brands(val) {
      this.searchValues("brands", val);
    },
    async search_projects(val) {
      this.searchValues("projects", val);
      // this.searchValues("responsible", "");
    },
    async search_responsible(val) {
      this.searchValues("responsible", val);
    },
    async isActive(val) {
      this.filterMainCredential.isActiveUser = val;
      this.searchValues("isActive", val);
    },
    async search_company(val) {
      this.searchValues("company", val);
      // this.searchValues("responsible", "");
    }
  },
  methods: {
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#calendario"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    close() {
      this.loadingInstance.close();
    },
    setShowDate(d) {
      this.showDate = d;
    },
    periodChangedCallback() {
      // This function is invoked whenever the date changes
      if(this.isInitializing) return
      this.getDataFromApi();
    },
    onClickDate(date, windowEvent) {
      //   console.log("***************************** date --- ", date);
    },

    item_mouseenter(element) {
      // console.log("item_mouseEnter --- ", element);
    },
    dropOnDate(calendarItem, date, windowEvent) {},
    async onClickEvent(calendarItem, windowEvent) {
      this.initEditTask( calendarItem );
    },

    isTaskOnTime,

    initEditTask(calendarItem) {
      let opts = {}
      if (calendarItem) {
        let calendarId = calendarItem.id;
        let trackingIndex = calendarId % this.SUB_MULTIPLIER;
        calendarId = ((calendarId - trackingIndex) / this.SUB_MULTIPLIER) % this.MULTIPLIER;
        let item = this.tasksData[calendarId - 1];
        opts = {
          id: _.get(item, "id"),
          task_endDate: this.convertDateToString(calendarItem.startDate),
          date: this.convertDateToString(calendarItem.startDate),
          ...item
        };
        if (trackingIndex) {
          let subItem = this.tasksData[calendarId - 1].taskTrackingsTaskId[ trackingIndex - 1 ];
          opts = {
            ...opts,
            ...subItem
          };
        }
      }
      this.editOpts = opts
      this.showEdit = true
    },

    convertDateToString(date) {
      return moment(new Date(date))
          .local()
          .format("YYYY-MM-DD");
    },

    async onItemEdit(item) {
      await this.initEditTask(null);
    },

    onChangeFilterMainCredentials(fieldname) {
      if (fieldname === "projects" || fieldname === "company")
        this.searchValues("responsible", "");
    },
    async searchValues(fieldname, val) {
      if (!val) val = "";
      let fetch_url = `${this.filter_main_credentials_fetch_url}?column=${fieldname}&isFullText=true&filterValue=${val}`;
      let form_data = { ...this.filterMainCredential };
      if (fieldname === "isActive") fieldname = "responsible";
      this.isSearching[fieldname] = true;
      const resp = await ApiService.post(fetch_url, form_data);
      if (fieldname === "brands") {
        this.filtered_brands = resp.data;
      } else if (fieldname === "projects") {
        this.filtered_projects = resp.data;
      } else if (fieldname === "responsible") {
        let { data } = resp
        // adding current user to filter values if not present
        const me = {
          ...this.user,
          title: [this.user.name, this.user.surname].filter(el=>!!el).join(" ")
        }
        const found = data.find(el=>el.id === me.id)
        if(!found){
          data.push(me)
        }
        this.filtered_responsible = data;
      } else if (fieldname === "company") {
        this.filtered_company = resp.data;
      }
      this.isSearching[fieldname] = false;
    },

    async onTaskChanged(task) {
      await this.getDataFromApi();
      this.$emit('onChange')
    },


    async onClickFilter() {
      if (this.$refs.form_search.validate()) {
        await this.getDataFromApi();
      }
    },

    getDateOfPreviousMonth(date) {
      let currentDate = new Date(date);
      currentDate.setMonth(currentDate.getMonth() - 2);
      return currentDate.toISOString().split("T")[0];
    },

    getDateOfNextMonth(date) {
      let currentDate = new Date(date);
      currentDate.setMonth(currentDate.getMonth() + 2);
      return currentDate.toISOString().split("T")[0];
    },

    getQueryParams() {
      let previousMonth = this.getDateOfPreviousMonth(this.showDate);
      let nextMonth = this.getDateOfNextMonth(this.showDate);
      let query_params_string = "";
      let query_params = [];
      query_params.push("monthly_st=" + previousMonth);
      query_params.push("monthly_en=" + nextMonth);


      const filterModel = objectToQueryParamsFilterModel(this.filterMainCredential);

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "tasks";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }

      try {
        const resp = await ApiService.get(fetch_url);
        this.tasksData = resp.data;
        await this.setCalendarItems();
        this.close();
      } catch (error) {
        this.close();
      }
      this.$emit('onFilterChange', this.filterMainCredential)
    },

    getDateFromRecurrence,

    isDateWithinRecurrence,

    getTitle(hour, priority, brandName, name) {
      if (!hour) hour = "";
      else hour = hour.slice(0, 5);
      let title = "";
      if (priority === "Alta")
        title = `<span class="triple-exclamation-mark">!!!</span>`;
      if (priority === "Media")
        title = `<span class="second-exclamation-mark">!!</span>`;
      if (priority === "Baja")
        title = `<span class="first-exclamation-mark">!</span>`;
      if (hour) title += `${hour} `;
      title += `${name} ${brandName}`;
      return title;
    },

    getStyleFromStatus,

    filterByStatus(status, completedDate, endDate) {
      if (!this.filterMainCredential.status.length) return false;
      let today = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      let computedStatus;
      if (status === "Finalizada") {
        if (completedDate <= endDate) {
          computedStatus = "Completed";
        } else {
          computedStatus = "Overdue";
        }
      } else if (status === "Pendiente") {
        if (today <= endDate) {
          computedStatus = "Pending";
        } else {
          computedStatus = "Delayed";
        }
      }
      return !this.filterMainCredential.status.includes(computedStatus);
    },


    async setCalendarItems() {
      let data_list = [];
      let count = 0;
      for (let index = 0; index < this.tasksData.length; index++) {
        let task = this.tasksData[index];
        if (task.type === "Recurrente") {
          task.recurrence = JSON.parse(task.recurrence);
        }
        let {
          name,
          priority,
          hour,
          task_startDate,
          task_endDate,
          recurrence_startDate,
          recurrence_endDate,
          status,
          type,
          recurrence,
          taskTrackingsTaskId,
          completedDate
        } = task;
        let brandName = task.brand?.name;

        if (task.type === "Puntual") {
          let eventId =
            (count * this.MULTIPLIER + index + 1) * this.SUB_MULTIPLIER;
          let style = this.getStyleFromStatus(task);
          let title = this.getTitle(hour, priority, brandName, name);
          if (!this.filterByStatus(status, completedDate, task_endDate)) {
            data_list.push({
              id: eventId,
              title,
              startDate: task_endDate,
              style
            });
            count++;
          }
        } else if (task.type === "Recurrente") {
          let limit = 3000;
          let taskTrackingIndex = 0;
          let nextDate = recurrence_startDate;

          for (let i = 0; i < limit; i++) {
            if (nextDate > recurrence_endDate) break;

            let {
              tempDate,
              style,
              title,
              isRemoved,
              trackingIndex
            } = this.getTaskDetails(task, taskTrackingIndex, nextDate);

            if (
              !isRemoved &&
              this.isDateWithinRecurrence(tempDate, recurrence)
            ) {
              let eventId =
                (count * this.MULTIPLIER + index + 1) * this.SUB_MULTIPLIER +
                (trackingIndex + 1);
              data_list.push({
                id: eventId,
                title,
                startDate: tempDate,
                style
              });
              count++;
            }

            taskTrackingIndex = this.updateTaskTrackingIndex(
              taskTrackingIndex,
              taskTrackingsTaskId,
              nextDate
            );
            nextDate = this.getDateFromRecurrence(nextDate, recurrence);
          }
        }
      }

      this.calendarItems = [...data_list];
    },



    getTaskDetails(task, taskTrackingIndex, nextDate) {
      let {
        taskTrackingsTaskId,
        name,
        priority,
        hour,
        status,
        completedDate,
        task_endDate,
        brand
      } = task;
      let tempDate = nextDate;
      let style = this.getStyleFromStatus(task);
      let isRemoved = this.filterByStatus(status, completedDate, tempDate);
      let title = this.getTitle(hour, priority, brand?.name, name);
      let trackingIndex = -1;

      if (
        taskTrackingIndex < taskTrackingsTaskId.length &&
        nextDate === taskTrackingsTaskId[taskTrackingIndex].date
      ) {
        let tracking = taskTrackingsTaskId[taskTrackingIndex];
        if (tracking.type === "Recurrente") {
          tracking.recurrence = JSON.parse(tracking.recurrence);
        }
        //tempDate = tracking.task_endDate; // DISABLED BECAUSE CAUSED ISSUE WITH COMPLETED RECURRENT TASKS
        style = this.getStyleFromStatus(tracking);
        isRemoved = this.filterByStatus(
          tracking.status,
          tracking.completedDate,
          tracking.task_endDate
        );
        title = this.getTitle(
          tracking.hour,
          tracking.priority,
          tracking.brand?.name,
          tracking.name
        );

        if (tracking.isRemoved) {
          isRemoved = true;
        }
        trackingIndex = taskTrackingIndex;
      }

      return {
        tempDate,
        style,
        title,
        isRemoved,
        trackingIndex
      };
    },

    updateTaskTrackingIndex(currentIndex, taskTrackingsTaskId, nextDate) {
      if (
        currentIndex < taskTrackingsTaskId.length &&
        nextDate === taskTrackingsTaskId[currentIndex].date
      ) {
        return currentIndex + 1;
      }
      return currentIndex;
    },

    // calendar should start with responsible filter set to current user
    async init() {
      this.searchValues("brands", "");
      this.searchValues("projects", "");
      this.searchValues("company", "");
      await this.searchValues("responsible", "");
      this.filterMainCredential.responsibleId = [this.user.id]
      this.isInitializing = false
      await this.getDataFromApi();
    }
  },
  async mounted() {
    await this.init();
  }
};
</script>
<style>
.calendar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  color: #000;
  height: 75vh;
  max-width: 97%;
  margin: 0 auto 20px;
}
.calendar .cv-weeks .cv-day {
  flex-flow: column;
  font-size: 0.9em;
}
.calendar .cv-weeks .cv-day.today {
  background-color: #ccc;
}
.calendar .cv-weeks .cv-day.today .cv-day-number {
  margin-top: 0.25rem;
  background-color: rgb(26, 115, 232);
  color: #fff;
  border-radius: 50%;
  width: 22px;
  height: 21px;
  text-align: center;
}
.calendar .cv-weeks .cv-day .cv-day-number {
  align-self: center;
}
.theme-default .cv-event {
  font-size: 0.9em;
  font-weight: 550 !important;
  color: #fff;
  padding-left: 7px;
  cursor: pointer;
  border-radius: 0.3em;
}
.theme-default .cv-event:hover {
  opacity: 0.7;
}
.triple-exclamation-mark {
  background-color: #ed2b2b;
  font-size: 12px;
  font-weight: bold !important;
  border-radius: 5px;
  border: 2px solid #fff;
  margin-right: 5px;
  padding: 1px 2px;
}
.second-exclamation-mark {
  background-color: #eded1dba;
  font-size: 12px;
  border-radius: 5px;
  border: 2px solid #fff;
  margin-right: 5px;
  padding: 1px 3px;
  letter-spacing: 0.8px;
}
.first-exclamation-mark {
  background-color: #349d34;
  font-size: 12px;
  padding: 1px 6px;
  border-radius: 5px;
  border: 2px solid #fff;
  margin-right: 5px;
}
</style>
