<template>
  <div>
    <Calendar
        @onFilterChange="onFilterChange"
        @onChange="onCalendarChange"
        :key="k0"
    ></Calendar>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <CalendarTasks
          @onChange="onTodayTasksChange"
          :calendarTitle="`Tareas Hoy`"
          :isToday="true"
          :tableName="`todayTasks`"
          :filters="filters"
          :key="k1"
        ></CalendarTasks>
      </v-col>

      <v-col cols="12" sm="12" md="12" lg="12">
        <CalendarTasks
            @onChange="onFutureTasksChange"
          :calendarTitle="`Tareas Próximos dias`"
          :tableName="`nextTasks`"
          :filters="filters"
          :key="k2"
        ></CalendarTasks>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Calendar from "./Calendar.vue";
import CalendarTasks from "./CalendarTasks.vue";
import _ from 'lodash'

export default {
  name: "VisionCalendar",
  components: {
    Calendar,
    CalendarTasks
  },
  methods: {
    onFilterChange(filters) {
      this.filters = _.clone(filters)
    },

    onCalendarChange() {
      this.k1++;
      this.k2++;
    },

    onTodayTasksChange() {
      this.k0++;
      this.k2++;
    },

    onFutureTasksChange() {
      this.k0++;
      this.k1++;
    },


  },
  data: function() {
    return {
      filters: null,
      k0: 0, // calendar comp. key
      k1: 0, // tasks today table comp. key
      k2: 0, // tasks future table comp. key
    }
  }
};
</script>

<style></style>
