import {WORKINGDAYS_ITEMS} from "../common/common";


export const getDateFromRecurrence = (date, recurrence) => {
    if (!recurrence) return null;
    let currentDate = new Date(date);
    let interval = parseInt(recurrence.interval);
    if (recurrence.freq === "days") {
        let isValid = 1;
        let count = 0;
        while (isValid) {
            if (count >= interval) break;
            currentDate.setDate(currentDate.getDate() + 1);
            let day = currentDate.getUTCDay();
            if (recurrence.isWeekend === "1") {
                if (day === 6) {
                    currentDate.setDate(currentDate.getDate() + 2);
                } else if (day === 0) {
                    currentDate.setDate(currentDate.getDate() + 1);
                }
            }
            count++;
        }
    } else if (recurrence.freq === "weeks") {
        if (recurrence?.workingDays && recurrence.workingDays.length) {
            let isValid = 1;
            while (isValid) {
                currentDate.setDate(currentDate.getDate() + 1);
                let day = currentDate.getUTCDay();
                if (!day) {
                    currentDate.setDate(currentDate.getDate() + 7 * interval - 7);
                }
                let item = WORKINGDAYS_ITEMS[day];
                if (recurrence.workingDays.includes(item.value)) {
                    break;
                }
            }
        } else {
            currentDate.setDate(currentDate.getDate() + 7 * interval);
        }
    } else if (recurrence.freq === "months") {
        currentDate.setMonth(currentDate.getMonth() + interval);
    } else if (recurrence.freq === "years") {
        currentDate.setFullYear(currentDate.getFullYear() + interval);
    }
    return currentDate.toISOString().split("T")[0];
}


export const isDateWithinRecurrence = (date, recurrence) => {
    if (!recurrence) return true;

    const dayOfWeek = new Date(date).getUTCDay();
    let isValid = true;

    if (recurrence.freq === "days" && recurrence.isWeekend === "1") {
        if (dayOfWeek === 0 || dayOfWeek === 6) isValid = false;
    }
    if (recurrence.freq === "weeks" && recurrence.workingDays?.length) {
        const workingDay = WORKINGDAYS_ITEMS[dayOfWeek];
        if (!recurrence.workingDays.includes(workingDay.value)) isValid = false;
    }

    return isValid;
}